import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Banner, StatusPill } from 'component-library';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../contexts/AppContext';
import { GetStatusColor, GetStatusText } from '../../../helpers/Custom';
import { HardwareSystem, Oem, OemIndirect, SoftwareGroup, SystemDetail } from '../../../models/ApiModels';
import { GetSoftwareGroups } from '../../../services/SoftwareUpdateService';
import { GetSystemAsync } from '../../../services/SystemService';
import { GetLHSAsync } from '../../../services/UnitService';
import SystemDetailsTabs from './SystemDetailsTabs';

export type OutletContextTypeSystemDetails = SystemDetail | any;

export function SystemDetailsLayout() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('System Details');
  const [system, setSystem] = useState<SystemDetail>();
  const [oldSystem, setOldSystem] = useState<SystemDetail>();
  const oems = useSelector((state: any) => state.oems.data);
  const [openPopup, setOpenPopup] = useState(false);
  const [leavingPage, setLeavingPage] = useState(false);
  const { isHumphreeUser, currentOem, currentOemTenantId } = useContext(AppContext);
  const { id } = useParams();
  const [lightningUnits, setLightningUnits] = useState<HardwareSystem[]>([]);
  const [groups, setGroups] = useState<SoftwareGroup[]>([]);
  const [showUpgradeLicense, setShowUpgradeLicense] = useState(false);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const navigate = useNavigate();
  const componentMounted = useRef(true);

  useEffect(() => {
    GetSystem();
    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, [id]);

  function GetSystem() {
    setLoading(true);
    if (id !== undefined) {
      GetSystemAsync(id)
        .then((system: SystemDetail) => {
          if (componentMounted.current) {
            setSystem({ ...system });
            setOldSystem(_.cloneDeep(system));
          }
          if (isHumphreeUser) {
            GetLHSAsync()
              .then((lhs: HardwareSystem[]) => {
                setLightningUnits(lhs);
                GetSoftwareGroups().then((softwareGroups: SoftwareGroup[]) => {
                  setGroups(softwareGroups);
                  setLoading(false);
                });
              })
              .catch(() => setLoading(false));
          } else setLoading(false);
        })
        .catch(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }

  const currentSelectedOem = useMemo(() => {
    if (system !== undefined && currentOem === undefined) {
      return oems.find((oem: Oem) => {
        return oem.id === system.oemId;
      });
    }
    return currentOem;
  }, [system]);

  const owner = useMemo(() => {
    if (system) {
      if (oems.find((oem: OemIndirect) => oem.id === system.oemId) !== undefined)
        return oems.find((oem: OemIndirect) => oem.id === system.oemId)?.name;
      else return currentOem?.name;
    }
  }, [system]);

  const hasChanged = useMemo(() => {
    return !_.isEqual(system, oldSystem);
  }, [oldSystem, system]);

  function GoBack() {
    if (hasChanged) {
      setOpenPopup(true);
    } else {
      setTimeout(() => {
        navigate('/systems');
      }, 1);
    }
  }

  return (
    <div className='max-w-[2000px]'>
      {loading ? (
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading system...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        system && (
          <>
            {system.status === 'PendingLicense' && (
              <div className='mb-12 w-full bg-error-50'>
                <Banner
                  icon
                  warning
                  text={
                    'A license upgrade have been purchased for this system. To install the new license, the LCP needs internet connection.'
                  }
                  color={'Super Light Red'}
                />
              </div>
            )}

            <div className='flex justify-between items-center mb-10'>
              <h1 className='md:prose-heading4 2xl:prose-heading3 items-center gap-1'>
                <span
                  className='cursor-pointer'
                  onClick={() => {
                    setLeavingPage(true);
                    GoBack();
                  }}
                >
                  Systems
                </span>
                {isHumphreeUser && (
                  <>
                    <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                    {currentSelectedOem?.name}
                  </>
                )}
                {currentOem?.parentOEMTenantId === null && (
                  <>
                    <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                    {owner}
                  </>
                )}
                <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                {system.controlUnitSerialNumber}
                {location.pathname.includes('createPreset') && (
                  <>
                    <FontAwesomeIcon icon={faChevronRight} className='mx-2' size='xs' />
                    Create new preset
                  </>
                )}
              </h1>
              <StatusPill color={GetStatusColor(system.status) as any} text={GetStatusText(system.status)} fixedSize />
            </div>

            {!location.pathname.includes('createPreset') && (
              <SystemDetailsTabs currentTab={currentTab} changeTab={(value: string) => setCurrentTab(value)} />
            )}

            <Outlet
              context={[
                system,
                setSystem,
                oldSystem,
                GetSystem,
                setLoading,
                loading,
                currentSelectedOem,
                hasChanged,
                lightningUnits,
                groups,
                leavingPage,
                setLeavingPage,
                openPopup,
                setOpenPopup,
                showUpgradeLicense,
                setShowUpgradeLicense,
                showOrderConfirmation,
                setShowOrderConfirmation,
              ]}
            />
          </>
        )
      )}
    </div>
  );
}
