import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Oem } from '../../models/ApiModels';

interface Props {
  oem: Oem;
  oemChosen: () => void;
  favouriteChanged?: () => void;
  isFavourite?: boolean;
  isActive?: boolean;
}

export function SidebarOemDropdownItem({ oem, oemChosen, favouriteChanged, isFavourite, isActive }: Props) {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col hover:bg-gray-5 px-7 border-b border-gray-10'>
      <div
        className='flex flex-row justify-between py-4 items-center cursor-pointer'
        onClick={() => {
          oemChosen();
          navigate('/');
        }}
      >
        <div className='w-3/4 flex flex-row items-center gap-1'>
          <span
            className={`${
              isActive ? 'prose-heading5' : 'prose-paragraphBase'
            } text-gray-100 whitespace-nowrap overflow-ellipsis overflow-hidden`}
          >
            {oem.name}
          </span>
        </div>
        {favouriteChanged && (
          <div
            onClick={(e: any) => {
              e.stopPropagation();
              favouriteChanged();
            }}
          >
            {isFavourite ? (
              <FontAwesomeIcon className='text-primary-100' icon={faStarSolid} title='Favorite' />
            ) : (
              <FontAwesomeIcon className='text-gray-40' icon={faStar} title='Not favourite' />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
