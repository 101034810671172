import { faBullseye, faChevronDown, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton } from 'component-library';
import { useContext, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { Oem } from '../../models/ApiModels';
import { SidebarOemDropdownItem } from './SidebarOemDropdownItem';

export default function SidebarOemDropdownIndirect() {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useDetectClickOutside({
    onTriggered: () => setDropdownOpen(false),
  });
  const { currentOemTenantId, setCurrentOemTenantId, setCurrentOem, currentOem } = useContext(AppContext);
  const oems = useSelector((state: any) => state.oems.data);
  const selectedOem = oems.find((oem: Oem) => oem.id === currentOemTenantId)?.name;

  return (
    <div>
      <div className='relative flex w-full justify-center items-center'>
        <div
          className='flex flex-row h-[54px] w-full bg-white border border-gray-10 items-center justify-between p-[14px] cursor-pointer'
          onClick={(e: any) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
        >
          <div className='2xl:prose-mainMenuL1ItemActive prose-mainMenuL2ItemActive whitespace-nowrap'>
            {selectedOem ? selectedOem : currentOem?.name}
          </div>

          <FontAwesomeIcon className='text-gray-100' icon={faChevronDown} title='Favorite' />
        </div>
        {dropdownOpen && (
          <div
            ref={ref}
            className='absolute flex flex-col w-[140%] bg-white shadow-dropdown top-[53px] left-0 z-20 border border-gray-10'
          >
            <div
              className={`${
                currentOem?.id === currentOemTenantId ? 'prose-heading5' : 'prose-paragraphBase'
              } text-gray-100 whitespace-nowrap overflow-ellipsis overflow-hidden px-7 py-4 hover:bg-gray-5 cursor-pointer flex flex-row items-center border-b border-gray-10`}
              onClick={() => {
                if (currentOem) setCurrentOemTenantId(currentOem.id);
                setDropdownOpen(!dropdownOpen);
                navigate('/');
              }}
            >
              <FontAwesomeIcon className='pr-2' icon={faBullseye} title='Home' />
              {currentOem?.name}
            </div>
            <div className={'max-h-72 overflow-auto'}>
              {oems.map((oem: Oem) => {
                const isActive = oem.id === currentOemTenantId;
                return (
                  <SidebarOemDropdownItem
                    key={oem.id}
                    oem={oem}
                    oemChosen={() => {
                      setCurrentOemTenantId(oem.id);
                      setDropdownOpen(!dropdownOpen);
                    }}
                    isActive={isActive}
                  />
                );
              })}
            </div>
            <div className='flex self-center my-4 px-4'>
              <PrimaryButton
                icon={faPlus}
                label='Add customer'
                onClick={() => {
                  setDropdownOpen(false);
                  navigate('/create/customer/');
                  setCurrentOemTenantId(currentOem ? currentOem?.id : '');
                }}
                size='small'
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
