import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InvertButton, Popup, PrimaryButton } from 'component-library';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { OemIndirect } from '../../models/ApiModels';
import { AddOemIndirect } from '../../services/OemService';
import { ActionType } from '../../store/actionTypes';
import { CreateIndirectCustomerInfo } from './CreateIndirectCustomerInfo';
import { CreateIndirectCustomerPricing } from './CreateIndirectCustomerPricing';

export const CreateIndirectCustomer = () => {
  const { currentOem } = useContext(AppContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initIndirectOem: OemIndirect = {
    id: '',
    name: '',
    currencyCode: 'SEK',
    parentOEMTenantId: currentOem?.id,
    settings: {
      invoiceStrategy: 'MonthlyInvoice',
      orderConfirmationEmail: '',
      priceList: [
        { packageId: 'PKG-1-1', price: 0 },
        { packageId: 'PKG-2-1', price: 0 },
        { packageId: 'PKG-3-1', price: 0 },
        { packageId: 'PKG-4-1', price: 0 },
        { packageId: 'FEAT-7-1', price: 0 },
      ],
    },
  };

  const [newIndirectOem, setNewIndirectOem] = useState<OemIndirect>(initIndirectOem);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewIndirectOem((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePriceChange = (index: number, newPrice: number) => {
    setNewIndirectOem((prev) => {
      const updatedPriceList = [...prev.settings.priceList];
      updatedPriceList[index] = { ...updatedPriceList[index], price: newPrice };

      return {
        ...prev,
        settings: {
          ...prev.settings,
          priceList: updatedPriceList,
        },
      };
    });
  };

  useEffect(() => {
    if (_.isEqual(initIndirectOem, newIndirectOem)) setHasChanges(false);
    else {
      setHasChanges(true);
    }
  }, [newIndirectOem]);

  function GoBack() {
    if (hasChanges) {
      setShowPopup(true);
    } else {
      setTimeout(() => {
        navigate(-1);
      }, 1);
    }
  }

  const saveIndirectCustomer = useCallback((newIndirectOem: OemIndirect) => {
    setLoading(true);
    AddOemIndirect(newIndirectOem)
      .then((response: any) => {
        setLoading(false);
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: 'You created a new customer!', status: 'success' },
        });

        setTimeout(() => {
          navigate('/');
        }, 200);
      })
      .catch((error) => {
        console.log('something went wrong');
        dispatch({
          type: ActionType.SET_SNACKBAR,
          payload: { heading: error.message, status: 'error' },
        });
        setLoading(false);
      });
  }, []);

  //Check for string
  const validOemInput = useMemo(() => {
    return newIndirectOem.name !== '';
  }, [newIndirectOem.name]);

  //Check for valid
  const validCustomerCreate = useMemo(() => {
    return validOemInput;
  }, [newIndirectOem]);

  return (
    <div className='grid gap-12 w-full'>
      <div className='flex gap-2 prose-heading3 text-primary-400 items-center pb-16'>
        <span className='cursor-pointer' onClick={() => GoBack()}>
          {currentOem?.name}
        </span>
        <FontAwesomeIcon icon={faChevronRight} size='sm' />
        <span>Create new customer</span>
      </div>
      <CreateIndirectCustomerInfo newIndirectOem={newIndirectOem} handleChange={handleChange} />
      <CreateIndirectCustomerPricing
        newIndirectOem={newIndirectOem}
        handleChange={handleChange}
        handlePriceChange={handlePriceChange}
      />
      <div className='flex gap-2 justify-end pt-10'>
        <InvertButton
          label='Cancel'
          disabled={loading}
          onClick={() => {
            if (hasChanges) setShowPopup(true);
            else
              setTimeout(() => {
                navigate('/');
              }, 1);
          }}
        />
        <PrimaryButton
          label='Create customer'
          disabled={!validCustomerCreate || loading}
          onClick={() => {
            setNewIndirectOem({ ...newIndirectOem });
            saveIndirectCustomer(newIndirectOem);
          }}
        />
      </div>
      {showPopup && (
        <Popup
          close={() => setShowPopup(false)}
          confirm={() =>
            setTimeout(() => {
              navigate('/');
            }, 1)
          }
          heading='You have unsaved changes.'
          paragraph="Do you want to discard any changes you've made?"
          status='error'
          cancelText='Go back'
          confirmText='Discard'
        />
      )}
    </div>
  );
};
