import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { OemIndirect } from '../../models/ApiModels';
type InfoProps = {
  newIndirectOem: OemIndirect;
  handlePriceChange: (item: any, index: any) => void;
};
function FunctionPackagesPrices({ newIndirectOem, handlePriceChange }: InfoProps) {
  const packages = useSelector((state: any) => state.configurations.packages);
  const autoTrim = [true, true, true, true];
  const autoList = [true, true, true, true];
  const pitchControl = [true, true, true, true];
  const coordinatedTurn = [false, true, false, true];
  const rollStab = [false, false, true, true];
  return (
    <div>
      <div className='grid grid-cols-9'>
        <div className='col-span-9 grid grid-cols-9 px-5 mb-2'>
          <span className='uppercase prose-labelTableColumn col-span-2'></span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Auto trim
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Auto list
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Pitch control
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Coordinated turn
          </span>
          <span className='uppercase prose-labelTableColumn col-span-1 flex justify-center text-center w-full'>
            Roll stabilization
          </span>
        </div>
        <div className='bg-gray-5 py-2 px-5 mb-1 prose-mainMenuL2Default col-span-9'>Manual</div>
        {newIndirectOem.settings.priceList.map((item, index) => (
          <div
            className='grid grid-cols-9 col-span-9 border-b border-gray-10 py-6 px-5 my-1 items-center gap-2'
            key={item.packageId}
          >
            <label className='col-span-2'>
              {packages.find((pack: any) => pack.id === item.packageId)?.displayName || 'Underwater lights'}
            </label>
            <div className='col-span-1 text-center'>
              {autoTrim[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
            </div>
            <div className='col-span-1 text-center'>
              {autoList[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
            </div>
            <div className='col-span-1 text-center'>
              {pitchControl[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
            </div>
            <div className='col-span-1 text-center'>
              {coordinatedTurn[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
            </div>
            <div className='col-span-1 text-center'>
              {rollStab[index] === true && <FontAwesomeIcon icon={faCheck} className='mx-2' size='lg' />}
            </div>
            <div className='flex gap-2 items-center w-full'>
              <input
                className='w-40 bg-gray-3 px-3 h-10 prose-paragraphSmaller]'
                type='number'
                placeholder='Input price...'
                value={item.price !== 0 ? item.price : undefined}
                onChange={(e) => handlePriceChange(index, Number(e.target.value))}
              />
              <div className='text-gray-60 font-bold'>{newIndirectOem.currencyCode}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FunctionPackagesPrices;
