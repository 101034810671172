import { faArrowDown, faCog, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, Tooltip } from 'component-library';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import portyHappy from '../../../../assets/porty_happy.svg';
import { AppContext } from '../../../../contexts/AppContext';
import { BoatModel } from '../../../../models/ApiModels';
import { GetSystemDatasetFile } from '../../../../services/SystemService';
import {
  autoTrimData,
  coTurnData,
  displayData,
  interceptorSetupData,
  listRollData,
  pitchData,
  rudderData,
  sensorData,
  speedSourceData,
} from '../../../DataExports/dataSetsData';
import { OutletContextTypeSystemDetails } from '../SystemDetailsLayout';
import { ConfigurationFunctionSetupSettings } from './ConfigurationFunctionSetupSettings';
import { ConfigurationOtherSettings } from './ConfigurationOtherSettings';
import ConfigurationPatch from './ConfigurationPatch';
import ConfigurationPresetInfo from './ConfigurationPresetInfo';
import { ConfigurationSystemSetupSettings } from './ConfigurationSystemSetupSettings';
import ConfigurationVisibilitySettings from './ConfigurationVisibilitySettings';
import ConfigurationWarranty from './ConfigurationWarranty';

export default function SystemDetailsConfiguration() {
  const navigate = useNavigate();
  const location = useLocation();
  const [hasSystemDataset, setHasSystemDataset] = useState(true);
  const { isHumphreeUser } = useContext(AppContext);
  const [system] = useOutletContext<OutletContextTypeSystemDetails[]>();
  const boatModels = useSelector((state: any) => state.boatModels.boatModels);
  const boatModel = boatModels.find((boatModel: BoatModel) => boatModel.id === system.boatModelId);
  const importedSensorData = sensorData(system);
  const importedRudderData = rudderData(system);
  const importedInterceptorSetupData = interceptorSetupData(system);
  const importedSpeedSourceData = speedSourceData(system);
  const importedAutoTrimData = autoTrimData(system);
  const importedListRollData = listRollData(system);
  const importedCoTurnData = coTurnData(system);
  const importedPitchData = pitchData(system);
  const importedDisplayData = displayData(system);

  useEffect(() => {
    const hasMetadata =
      system.lightningHardwareSystem.controlUnit.metadata.fileName !== null &&
      system.lightningHardwareSystem.primaryControlPanel.metadata.fileName !== null;
    if (hasMetadata) {
      setHasSystemDataset(true);
    } else setHasSystemDataset(false);
  }, []);

  async function GetDatasetFile(fileNameId: string) {
    GetSystemDatasetFile(fileNameId)
      .then((res: any) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = `${fileNameId}.hdst`;
        link.click();
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  return (
    <div className='pt-10'>
      <div className='flex flex-col gap-16'>
        {system.presetId ? <ConfigurationPresetInfo system={system} boatModel={boatModel} /> : null}
        <div className={`flex flex-col pb-5 ${!hasSystemDataset && 'border-b border-gray-10'}`}>
          <h1 className='prose-heading4 mb-5'>
            <FontAwesomeIcon icon={faCog} style={{ marginRight: '0.5rem' }} />
            System dataset
          </h1>
          {hasSystemDataset ? (
            <>
              <div className='flex justify-between py-5 mb-5 border-y border-gray-10'>
                <h2 className='flex gap-2'>
                  Dataset updated date
                  <Tooltip
                    heading='Dataset update date'
                    description='When the system connects to the internet, it uploads its latest dataset.'
                    darkmode
                  />
                </h2>
                <p>
                  {system.lightningHardwareSystem.controlUnit.metadata.createdDate &&
                    moment(system.lightningHardwareSystem.controlUnit.metadata.createdDate).format('YYYY-MM-DD, HH:mm')}
                </p>
              </div>
              <div className='flex flex-wrap gap-8 ml-auto'>
                {isHumphreeUser && (
                  <>
                    <PrimaryButton
                      label='Download LCU dataset'
                      icon={faArrowDown}
                      onClick={async () =>
                        await GetDatasetFile(system.lightningHardwareSystem.controlUnit.metadata.fileName)
                      }
                    />
                    <PrimaryButton
                      label='Download LCP dataset'
                      icon={faArrowDown}
                      onClick={async () =>
                        await GetDatasetFile(system.lightningHardwareSystem.primaryControlPanel.metadata.fileName)
                      }
                    />
                  </>
                )}
                <PrimaryButton
                  label={
                    system.boatModelId !== '' ? 'Copy settings to a new preset' : 'Assign boat model to create preset'
                  }
                  icon={faCopy}
                  disabled={system.boatModelId === ''}
                  onClick={() => navigate(location.pathname + '/../createPreset')}
                />
              </div>
            </>
          ) : (
            <>
              <p className='pb-5'>
                Soon we will release a new software version that will sync system settings with the portal. You will be
                able to see all settings and create presets from this view.
              </p>
              <div className='max-w-[38rem] my-12 m-auto'>
                <img className='object-contain' src={portyHappy} alt='porty happy' />
                <p className='prose-labelStandard uppercase text-center'>Cool stuff coming soon!</p>
              </div>
            </>
          )}
        </div>
        {hasSystemDataset && (
          <>
            <ConfigurationSystemSetupSettings
              sensorData={importedSensorData}
              rudderData={importedRudderData}
              interceptorSetupData={importedInterceptorSetupData}
              speedSourceData={importedSpeedSourceData}
            />
            <ConfigurationFunctionSetupSettings
              autoTrimData={importedAutoTrimData}
              listRollData={importedListRollData}
              coTurnData={importedCoTurnData}
              pitchData={importedPitchData}
            />
            <div className='flex gap-10 flex-wrap'>
              <ConfigurationOtherSettings displayData={importedDisplayData} />
              <ConfigurationVisibilitySettings
                data={system.lightningHardwareSystem.controlUnit.metadata.menuVisibility}
              />
            </div>
          </>
        )}
        {boatModel?.patches && <ConfigurationPatch boatModel={boatModel} />}
        {system.warrantySubmissionDate && <ConfigurationWarranty />}
      </div>
    </div>
  );
}
