import { Tooltip } from 'component-library';
import { OemIndirect } from '../../models/ApiModels';
import FunctionPackagesPrices from './FunctionPackagesPrices';
type PriceProps = {
  newIndirectOem: OemIndirect;
  handleChange: (e: any) => void;
  handlePriceChange: (item: any, index: any) => void;
};
export const CreateIndirectCustomerPricing = ({ newIndirectOem, handleChange, handlePriceChange }: PriceProps) => {
  return (
    <div className='grid gap-5'>
      <h4 className='prose-heading4'>Upgrade prices</h4>
      <p className='prose-paragraphBase pb-3 border-b border-gray-5 max-w-[52ch]'>
        Input prices and currency displayed for the customer when purchasing function package upgrades for the portal.
        You will still be invoiced your agreed upon price from Humphree.
        <br />
        <br />
        If a customer upgrades from one package to another, the difference will be displayed.
      </p>
      <div className='flex gap-2 pb-3 border-b border-gray-3'>
        <span className='prose-paragraphBase mb-2'>
          Currency <span className=' text-error-100'>*</span>
        </span>
        <Tooltip darkmode description='TBA' />
        <div className='ml-auto'>
          <input
            className='bg-gray-3 px-3 h-10 mt-2 prose-paragraphSmaller]'
            type='text'
            name='currencyCode'
            placeholder='Input currency...'
            value={newIndirectOem.currencyCode}
            onChange={handleChange}
            maxLength={3}
          />
        </div>
      </div>
      <FunctionPackagesPrices newIndirectOem={newIndirectOem} handlePriceChange={handlePriceChange} />
    </div>
  );
};
